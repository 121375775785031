import { Injectable, WritableSignal, signal } from '@angular/core';
import { ESearchCategory, IHit, IHistoryResults, THistoryHit } from '../search-bar/search-bar.interface';
import { environment } from 'src/environments/environment';
import { TGoogleCaptchaAssessment } from './search-modal.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchModalService {
  public searchModalIsOpen$: WritableSignal<boolean> = signal(false);
  public captchaModalIsOpen$: WritableSignal<boolean> = signal(false);
  public searchHistory$: WritableSignal<IHistoryResults | undefined> = signal<IHistoryResults | undefined>(this.getSearchHistory());

  public toggleSearchModal(open: boolean): void {
    this.searchModalIsOpen$.set(open);
  }

  public toggleCaptchaModal(open: boolean): void {
    this.captchaModalIsOpen$.set(open);
  }

  public addToSearchHistory(categoryType: ESearchCategory, item: IHit): void {
    let history = this.getSearchHistory();

    if (!history) {
      history = {
        hits: [] as THistoryHit[],
      } as IHistoryResults;
    }

    item.categoryType = categoryType;

    history.hits.unshift(item as THistoryHit);

    if (history.hits.length > 10) {
      history.hits.pop();
    }

    history.label = ESearchCategory.RECENT_SEARCHES;

    localStorage.setItem('recentSearch', JSON.stringify(history));
    this.searchHistory$.set(history);
  }

  public getSearchHistory(): IHistoryResults | undefined {
    try {
      return JSON.parse(localStorage.getItem('recentSearch') || '');
    } catch {
      return undefined;
    }
  }

  public clearSearchHistory(): void {
    localStorage.removeItem('recentSearch');
    this.searchHistory$.set(undefined);
  }

  public reCaptcha(): Promise<TGoogleCaptchaAssessment> {
    return new Promise((resolve) => {
      (window as any).grecaptcha.enterprise.ready(async () => {
        const token = await (window as any).grecaptcha.enterprise.execute(environment.recaptchaKey, { action: 'SEARCH' });
        const response = await fetch(`https://recaptchaenterprise.googleapis.com/v1/projects/stellar-stream-407120/assessments?key=${environment.googleApiKey}`, {
          method: 'POST',
          body: JSON.stringify({
            event: {
              token: token,
              expectedAction: 'SEARCH',
              siteKey: environment.recaptchaKey,
            },
          }),
        });
        const data = await response.json();
        resolve(data);
      });
    });
  }
}
